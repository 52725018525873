import {
  ApiJobDetailOk, ApiJobUserDetailOk, ApiPrevNextJobOk,
} from '@type/v1-api-types';
import {
  Button, Card, LinkStyled, SanitizeHTML,
} from '@components/common';
import { detailSectionsStyles } from '@components/templates/job/detail/detailSectionStyles';
import { useTranslation } from 'next-i18next';
import { clsx } from 'clsx';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ModalPrevNextJobs } from '@type/job';
import { AiEvaluateProfile } from '@components/templates/job/detail/sections/AiEvaluateProfile';
import { LanguageIcon } from '@heroicons/react/16/solid';
import {
  CompanyShortPlaceholder,
  CompanyWidgetPlaceholder,
  DescriptionPlaceholder,
  TagsPlaceholder,
  TitlePlaceholder,
  SummaryPlaceholder,
} from './placeholders';
import {
  Benefits,
  CompanyWidget,
  JobActions,
  Navigation,
  ShareAndNavigation,
  SimilarJobs,
  Tags,
  CompanyShort,
  Summary,
  OnlineInterview,
} from './sections';

// Interface
// ************************************
export interface JobDetailProps {
  job: (ApiJobDetailOk & ApiJobUserDetailOk) | null,
  locale: Locale,
  similarJobs?: ApiSchema<'JobResult'> | null,
  apiPrevNextJobs?: ApiPrevNextJobOk | null,
  modalPrevNextJobs?: ModalPrevNextJobs,
  closeModal?: () => void,
  mutateUserDetail?: () => void,
  isLoading?: boolean,
  isSinglePage?: boolean,
  isSkeletonPlaceholder?: boolean,
  jobTitle?: string,
  modalNavigateToJob?: (nextJobSlug: string, direction: 'prev' | 'next') => void,
  mode?: 'default' | 'preview',
}

/**
 * @description Job Detail. Renders job detail.
 * - **job** - ApiJobDetailOk | null - The job object.
 * - **locale** - Locale - The current app locale.
 * - **similarJobs** - ApiSchema<'JobResult'> - Similar jobs list.
 * - **apiPrevNextJobs** - ApiPrevNextJobOk - Prev & next jobs, for in page navigation (not modal). There are populated via an API request.
 * - **modalPrevNextJobs** - ModalPrevNextJobs - Prev & next jobs, for modal navigation. The data comes from SWR.
 * - **closeModal** - function - Function to close the modal, if opened in a modal, optional.
 * - **isLoading** - boolean - A flag indicating whether the job detail request is loading or not, optional.
 * - **isSinglePage** - boolean - A flag indicating if the page is single
 * - **isSkeletonPlaceholder** - boolean - A flag indicating whether this component is used as a skeleton placeholder for prev / next navigation in modal.
 * - **jobTitle** - string - The job title. Use this prop to display the job title regardless is the job details is loading or not.
 * - **modalNavigateToJob** - function - The function to use to navigate to prev / next job (in modal).
 * - **mode** - 'default' | 'preview' - The mode of the component. Default is 'default'.
 */
export const JobDetail = (props: JobDetailProps) => {
  const {
    job,
    locale,
    mutateUserDetail,
    similarJobs,
    apiPrevNextJobs,
    isSinglePage,
    modalPrevNextJobs,
    closeModal,
    isLoading,
    isSkeletonPlaceholder,
    jobTitle,
    modalNavigateToJob,
    mode = 'default',
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Helpers
  // *****************************************
  const isModal = useMemo(
    () => !!closeModal,
    [closeModal],
  );

  const showPlaceholders = useMemo(
    () => isLoading || isSkeletonPlaceholder,
    [isLoading, isSkeletonPlaceholder],
  );

  // Memoized value to determine if translations are available for the job.
  const hasTranslations = useMemo(
    () => job?.translations
                    && job?.translations.length > 0
                    && job?.translations.some((tr) => tr.locale === locale && tr.translated === true),
    [job?.translations, locale],
  );

  // State to determine whether to show translations or original content.
  // The initial value is set based on whether translations are available (hasTranslations).
  const [showTranslations, setShowTranslations] = useState(hasTranslations);

  // Effect to sync the `showTranslations` state with the availability of translations (`hasTranslations`).
  useEffect(() => {
    setShowTranslations(hasTranslations);
  }, [hasTranslations]);

  // Function to retrieve the translated content for a given field.
  // - If translations are not being shown (`showTranslations` is false), it returns the original value.
  // - If translations are enabled, it searches the `job.translations` array for a matching translation.
  // - If no translation is found, it falls back to the original value.
  const getTranslatedField = useCallback((fieldName: string, original = ''): string => {
    if (!showTranslations) return original;

    return (
      job?.translations?.find((tr) => tr.field === fieldName && tr.locale === locale)?.content
      || original // Fallback to the original content if no translation is found.
    );
  }, [job?.translations, locale, showTranslations]);

  // Displayed job title
  const displayedJobTitle = useMemo(
    () => (jobTitle || getTranslatedField('title', job?.title)),
    [jobTitle, job?.title, getTranslatedField],
  );

  // Toggle the `showTranslations` state.
  const toggleShowTranslations = () => {
    setShowTranslations(!showTranslations);
  };

  // Render component
  // ************************************
  return (
    <div className={clsx('relative w-full', {
      'lg:before:block lg:before:absolute lg:before:top-0 lg:before:bottom-0 lg:before:left-0 lg:before:right-1/2 lg:before:bg-primary/10': isModal,
    })}
    >
      <div className={clsx('relative bg-surface lg:flex', {
        'mx-auto max-w-[1410px]': isModal,
        'sm:rounded-md': !isModal,
      })}
      >

        {/* Job Highlighted Border */}
        {job?.highlighted && !isModal && (
          <span className="absolute -top-0.5 z-1 block h-1.5 w-full bg-info sm:rounded-t-md" />
        )}

        {/* Job Card Sidebar */}
        <div className={clsx(
          'relative bg-gradient-to-b from-primary/10 from-0% via-primary/15 via-90% to-primary/20 to-100% px-4 pb-12 pt-18 lg:min-h-screen lg:w-2/5 lg:bg-gradient-to-r lg:p-8',
          {
            'sm:rounded-t-md lg:rounded-tr-none lg:rounded-bl-md': !isModal,
          },
        )}
        >

          <div className={clsx(isModal && 'lg:top-8', !isModal && 'lg:top-[--bj-mobile-menu-offset] lg:-mt-7 lg:pt-7')}>

            {/* Job Translations */}
            {hasTranslations && (
              <Button
                styling="none"
                className={clsx('mb-3 flex items-center gap-x-1.5 text-sm text-ink-medium hover:text-ink')}
                onClick={toggleShowTranslations}
              >
                <LanguageIcon className="size-4" />
                {showTranslations ? t('global.label.see-original') : t('global.label.see-translation')}
              </Button>
            )}

            {/* Job Title */}
            {displayedJobTitle && (
              <h2 className="mb-3 mt-1 break-words text-6xl font-semibold leading-tight tracking-tighter">
                {job ? (
                  <LinkStyled styling="none" href={`/job/${job.slug}`} className="hover:text-primary">
                    { displayedJobTitle }
                  </LinkStyled>
                ) : displayedJobTitle}
              </h2>
            )}

            {job && (
              <>
                {/* Inactive job */
                  (!job.active && !job?.workplaceJob) && (
                    <Card className="mt-8 rounded border border-primary bg-surface px-4 py-2">
                      <SanitizeHTML
                        as="div"
                        htmlContent={similarJobs?.items?.length ? t('job.detail.inactive') : t('job.detail.inactive.noLink')}
                        className="prose"
                      />
                    </Card>
                  )
                }

                {/* Job summary */}
                <Summary job={job} />

                {/* Company short */}
                {mode === 'default' && (
                  <>
                    <CompanyShort employer={job.employer} workplace={job.workplace} />
                    <AiEvaluateProfile
                      mutateUserDetail={mutateUserDetail}
                      currentEvaluation={job?.evaluation}
                      jobSlug={job?.slug}
                      locale={locale}
                    />
                  </>
                )}
              </>
            )}

            {showPlaceholders && (
              <>
                {!displayedJobTitle && <TitlePlaceholder />}
                <SummaryPlaceholder />
                <CompanyShortPlaceholder />
              </>
            )}
          </div>

          {/*
            Share and navigation - for mobile
            (rendered and the end of sidebar so that when opened in modal, the focused element will always be the job title)
          */}
          {mode === 'default' && (
            <ShareAndNavigation
              job={job}
              context="mobile"
              apiPrevNextJobs={apiPrevNextJobs}
              modalPrevNextJobs={modalPrevNextJobs}
              closeJobModal={!isSkeletonPlaceholder ? closeModal : undefined}
              modalNavigateToJob={modalNavigateToJob}
              className="absolute inset-x-0 top-1 px-4 py-2 lg:hidden"
            />
          )}
        </div>

        {/* Job Card Content */}
        <div className={mode === 'default' ? 'p-4 lg:w-3/5 lg:p-8' : 'px-4 pb-4 lg:w-3/5 lg:px-8 lg:pb-8'}>

          {/* Share and navigation - for breakpoint `lg` and up */}
          {mode === 'default' && (
            <ShareAndNavigation
              job={job}
              apiPrevNextJobs={apiPrevNextJobs}
              modalPrevNextJobs={modalPrevNextJobs}
              closeJobModal={!isSkeletonPlaceholder ? closeModal : undefined}
              modalNavigateToJob={modalNavigateToJob}
              className="hidden lg:block lg:border-b lg:border-input lg:pb-8"
            />
          )}

          {/* Tags */
            (!!job?.spokenLanguages?.length) && (
              <Tags spokenLanguages={job.spokenLanguages} />
            )
          }

          {/* Job description */
            job?.description && (
              <SanitizeHTML
                as="div"
                htmlContent={getTranslatedField('description', job.description)}
                className={clsx(detailSectionsStyles.common, 'prose', { '!mt-0 !border-t-0': !job.escoSkills?.length && !job.spokenLanguages?.length })}
                // Allow iframes in the description
                config={{
                  ADD_TAGS: ['iframe'],
                  ADD_ATTR: [
                    'allowfullscreen',
                    'frameborder',
                    'src',
                    'width',
                    'height',
                    'class',
                    'allow',
                  ],
                }}
              />
            )
          }

          {/* Job benefits */
            !!job?.benefits?.length && (
              <Benefits benefitsGroups={job.benefits} />
            )
          }

          {/* Online interview */
            !!job?.questions?.length && (
              <OnlineInterview questions={job.questions} />
            )
          }

          {/* Interview Stages */
            job?.interviewStages && (
              <div className={detailSectionsStyles.common}>
                <h3 className={detailSectionsStyles.title}>
                  { t('job.add.label.interview_stages') }
                </h3>
                <div className="mt-4">
                  {job.interviewStages}
                </div>
              </div>
            )
          }

          {/* Company Widget Box */ }
          {job && mode === 'default' && (
            <CompanyWidget employer={job.employer} workplace={job.workplace} />
          )}

          {/* Similar Jobs */
            !!similarJobs?.items?.length && job && (
              <SimilarJobs similarJobs={similarJobs} jobSlug={job.slug} isModal={isModal} locale={locale} />
            )
          }

          {/* Navigation */
            apiPrevNextJobs && <Navigation apiPrevNextJobs={apiPrevNextJobs} />
          }

          {/* Job actions */
            (job?.active || job?.workplaceJob) && mode === 'default' && <JobActions isSinglePage={isSinglePage} job={job} />
          }

          {/* Job Content Loading Placeholders */}
          {showPlaceholders && (
            <div className="mr-2 mt-10 space-y-10 pt-10 md:pt-0">
              <TagsPlaceholder />
              <DescriptionPlaceholder />
              <CompanyWidgetPlaceholder />
            </div>
          )}

        </div>
      </div>
    </div>
  );
};
